import React, { useState, useCallback, useMemo, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Grid,
  Select,
  MenuItem,
  FormControl,
  FormControlLabel,
  ListItem,
  ListItemText,
  CircularProgress,
  Checkbox,
} from '@material-ui/core'
import { useParams } from '@reach/router'
import { useSelector, useDispatch } from 'react-redux'
import { CloseCircle } from '@refera/ui-icons'

import Button, { BUTTON_COLOR } from '_components/button'
import TextField from '_components/textfield'
import { createMessage, updateMessage, deleteMessageAttachment } from '_modules/messages/actions'
import { userSelector } from '_modules/authentication/selectors'
import Theme from '@refera/ui-core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { getAllStepStatusOptionsSelector } from '_/modules/service-orders/selectors'
import { getStepStatusOptions } from '_/modules/service-orders/actions'
import { Preview, useConfirm, DatePicker, Loader } from '@refera/ui-web'
import { Attachments } from '_/components/refera-ui'

import ConfirmRemoveDialog from '_components/dialogs/ConfirmRemoveDialog'
import ConfirmCancelJustified from '_components/modal/confirm-cancel-justified'
import useToggle from '_hooks/use-toggle'

import useStyles from './styles'
import { ConfidentialDataWarning } from '_/components/ConfidentialDataWarning'
import useRolePermission from '_/hooks/use-role-permission'
import { useToast } from '_/hooks/use-toast'

export default function NewCommentModal(props) {
  const { newCommentModal, setNewCommentModal, handleUpdate } = props
  const { showToast } = useToast()
  const styles = useStyles()
  const dispatch = useDispatch()
  const { serviceOrderId } = useParams()
  const user = useSelector(userSelector)
  const { isAdmin: isCsRefera } = useRolePermission()

  const [isLoading, setIsLoading] = useState(false)

  const [step, setStep] = useState(null)
  const [hasTask, setHasTask] = useState(isCsRefera ? 'no' : null)
  const [taskDue, setTaskDue] = useState(null)
  const [finishIn, setFinishIn] = useState(null)
  const [reminder, setReminder] = useState(false)
  const [workingDays, setWorkingDays] = useState(0)
  const [isTaskDueRequired, setIsTaskDueRequired] = useState(false)
  const [isWorkingDaysRequired, setIsWorkingDaysRequired] = useState(false)

  const stepStatusList = useSelector(getAllStepStatusOptionsSelector)

  function resetFields() {
    setTaskDue(null)
  }

  const handleSelecStep = useCallback(event => {
    setStep(event.target.value)
  }, [])

  const handleSelecthasTask = useCallback(event => {
    const { value } = event.target
    setHasTask(value)
    setIsTaskDueRequired(value !== 'no')
  }, [])

  const handletaskDueChange = useCallback(date => {
    const formatDate = date?.format('YYYY-MM-DD')
    setTaskDue(formatDate)
  }, [])

  const handlefinishInChange = useCallback(date => {
    const formatDate = date?.format('YYYY-MM-DD')
    setFinishIn(formatDate)
  }, [])

  const handleReminderChange = useCallback(event => {
    const isChecked = event.target.checked
    setReminder(isChecked)
    setIsWorkingDaysRequired(isChecked)
  }, [])

  const handleWorkingDaysChange = useCallback(event => {
    setWorkingDays(parseInt(event.target.value, 10) || 0)
  }, [])

  const [commentDescription, setCommentDescription] = useState('')
  const [visibility, setVisibility] = useState('')
  const [selectedIndex, setSelectedIndex] = useState(null)

  const defaultPreviewState = {
    visible: false,
    selectedIndex: undefined,
  }

  const [picturesLoading, setPicturesLoading] = useState(false)
  const [editAttachments] = useToggle()
  const [dialogMessage, setDialogMessage] = useState('')
  const { isConfirmed } = useConfirm()
  const [previewState, setPreviewState] = useState(defaultPreviewState)
  const [toDelete, setToDelete] = useState([])

  const isIntermediaryUser = useMemo(
    () => user?.getRoles?.includes('cs_manager') || user?.getRoles?.includes('cs_approver'),
    [user]
  )

  const [confirmCancelJustified, setConfirmCancelJustified] = useState({
    isOpen: false,
    subTitle: '',
    messageId: '',
  })

  const obj = {
    Público: ['Qualquer pessoa poderá visualizar', 'PUBLIC'],
    'Minha empresa + Refera': [
      'Minha empresa e a Refera poderão visualizar',
      'MY_COMPANY_CS_REFERA',
    ],
    'Minha empresa + Intermediárias': [
      'Minha empresa e Intermediária poderão visualizar',
      'MY_COMPANY_CS_AGENCY',
    ],
    'Minha empresa': ['Apenas usuários da sua empresa poderão visualizar', 'MY_COMPANY'],
    'Apenas eu': ['Somente você poderá visualizar', 'JUST_ME'],
  }

  const visibleTo = Object.keys(obj).filter(label => {
    if (isCsRefera && label === 'Minha empresa + Refera') return false

    return !(isIntermediaryUser && label === 'Minha empresa + Intermediárias')
  })

  const menuProps = useMemo(
    () => ({
      getContentAnchorEl: null,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    }),
    []
  )

  const handleChangeDescription = e => {
    setCommentDescription(e.target.value)
  }
  const [commentAttachments, setCommentAttachment] = useState([])

  useEffect(() => {
    dispatch(getStepStatusOptions())
    if (newCommentModal?.comment?.messageAttachment) {
      setCommentAttachment(
        newCommentModal?.comment?.messageAttachment.map(file => ({
          file: file?.file,
          id: file?.id,
        }))
      )
      setPicturesLoading(false)
    }
  }, [newCommentModal?.comment?.messageAttachment])

  useEffect(() => {
    if (newCommentModal?.comment) {
      setTaskDue(newCommentModal?.comment.taskDue)
      setReminder(newCommentModal?.comment.reminder)
      setStep(newCommentModal?.comment.step)
      setVisibility(obj[newCommentModal?.comment.visibleTo][1])
      setCommentDescription(newCommentModal?.comment.message)
      setWorkingDays(newCommentModal?.comment.workingDays)
      setFinishIn(newCommentModal?.comment.completedIn)
    }
  }, [newCommentModal?.comment])

  const imageAttachment = useMemo(
    () =>
      commentAttachments.filter(file =>
        file instanceof File ? file.type !== 'application/pdf' : file.file.indexOf('.pdf') === -1
      ),
    [commentAttachments]
  )

  const PDFAttachment = useMemo(
    () =>
      commentAttachments.filter(file =>
        file instanceof File ? file.type === 'application/pdf' : file.file.indexOf('.pdf') !== -1
      ),
    [commentAttachments]
  )

  const handleSubmit = async e => {
    e.preventDefault()
    setIsLoading(true)

    const newAttachments = commentAttachments.filter(item => item?.id === undefined)
    if (newCommentModal?.comment) {
      let values = {
        id: newCommentModal?.comment.id,
        message: commentDescription,
        visibleTo: visibility,
        messageAttachment: newAttachments,
        step,
        reminder,
        taskExpectedSomeone: hasTask,
        taskDue,
        completedIn: finishIn,
        workingDays,
      }

      if (toDelete.length > 0) {
        toDelete.map(fileID =>
          dispatch(deleteMessageAttachment(newCommentModal?.comment.id, fileID)).catch(() => {
            return showToast({
              type: 'error',
            })
          })
        )
        setToDelete([]) // RESETS ARRAY TO AVOID UNECESSARY DELETE REQUESTS ON FUTURE SAVE ATTEMPTS
      }
      if (visibility) {
        values = {
          ...values,
          visibleTo: visibility,
        }
      }

      dispatch(updateMessage(values.id, values))
        .then(() => {
          handleUpdate()
        })
        .catch(() => {
          showToast({
            type: 'error',
            message: 'Ocorreu um erro ao salvar o comentário. Tente novamente mais tarde.',
          })
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      const values = {
        origin: 'comment',
        type: 'request',
        service_order: serviceOrderId,
        parent_message: null,
        subject: 'others',
        message: commentDescription,
        sender: user.id,
        sender_name: user.name,
        resolved: true,
        reminder,
        visibleTo: visibility,
        messageAttachment: newAttachments,
        step,
        taskExpectedSomeone: hasTask,
        taskDue,
        completedIn: finishIn,
        workingDays,
      }
      await dispatch(createMessage(values))
        .then(() => {
          handleUpdate()
          resetFields()
        })
        .catch(() => {
          return showToast({
            type: 'error',
            message: 'Ocorreu um erro ao salvar o comentário. Tente novamente mais tarde.',
          })
        })
        .finally(() => {
          setIsLoading(false)
        })
      setPicturesLoading(false)
    }

    setCommentDescription('')
    setNewCommentModal({ ...newCommentModal, isOpen: false })
  }

  const handleCloseModal = useCallback(() => {
    setNewCommentModal({ ...newCommentModal, isOpen: false })
    setVisibility(null)
  })

  const handleSelectResponsible = useCallback(
    event => {
      setSelectedIndex(event.target.value)
      setVisibility(event.target.value)
    },
    [visibility]
  )

  const handleRemoveAttachments = useCallback(
    async (index, pdf) => {
      setDialogMessage('Você deseja deletar este item?')
      const confirmed = await isConfirmed()
      if (confirmed) {
        const currentFile = pdf ? PDFAttachment[index] : imageAttachment[index]
        if (currentFile?.id) {
          setToDelete([...toDelete, currentFile.id])
          setCommentAttachment(old => old.filter(current => current.id !== currentFile.id))
        } else {
          setCommentAttachment(old =>
            old.filter(current => current.lastModified !== currentFile?.lastModified)
          )
        }
      }
    },
    [commentAttachments, setDialogMessage]
  )

  const PreviewPictureComponent = useMemo(
    () => (
      <Preview
        open={previewState.visible}
        onClose={() => setPreviewState(() => defaultPreviewState)}
        selectedIndex={previewState.selectedIndex}
        images={
          imageAttachment &&
          imageAttachment.map((item, index) =>
            item.id
              ? {
                  id: item.id,
                  url: item.file,
                }
              : {
                  id: index,
                  url: URL.createObjectURL(item),
                }
          )
        }
      />
    ),
    [previewState, commentAttachments]
  )

  const handleDefaultTaskValue = useMemo(() => {
    if (isCsRefera) {
      setHasTask('no')
      return 'no'
    }

    if (newCommentModal?.comment) {
      setHasTask(newCommentModal?.comment.taskExpectedSomeone)
      return newCommentModal?.comment.taskExpectedSomeone
    }

    setHasTask('')
    return ''
  }, [isCsRefera, newCommentModal])

  return (
    <Dialog maxWidth="lg" fullWidth open={newCommentModal.isOpen}>
      <Loader hasBackdrop open={isLoading || picturesLoading} label="Aguarde..." />
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          <Grid className={styles.header}>
            <Grid>
              <Typography variant="h6">{newCommentModal.title}</Typography>
            </Grid>
            <Button onClick={handleCloseModal} variant="ghost">
              <CloseCircle color={Theme.Colors.Grayscale.SixtyFour} className={styles.closeIcon} />
            </Button>
          </Grid>
        </DialogTitle>

        <DialogContent className={styles.dialogContent} dividers>
          <Grid className={styles.section}>
            <Grid className={styles.column}>
              <Grid className={styles.subsection}>
                <Grid style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <Typography className={styles.text}>
                    Quem pode visualizar?<b className={styles.required}> *</b>
                  </Typography>
                  <FormControl required fullWidth className={styles.select}>
                    <Select
                      required
                      onChange={handleSelectResponsible}
                      MenuProps={menuProps}
                      IconComponent={ExpandMoreIcon}
                      defaultValue={
                        newCommentModal?.comment ? obj[newCommentModal?.comment.visibleTo][1] : ''
                      }
                      displayEmpty
                      style={{ height: 65 }}
                    >
                      <MenuItem value="" label="">
                        <ListItem>
                          <ListItemText primary="" secondary="" />
                        </ListItem>
                      </MenuItem>
                      {visibleTo.map(label => (
                        <MenuItem
                          key={label}
                          value={obj[label][1]}
                          label={label}
                          selected={obj[label] === selectedIndex}
                        >
                          <ListItem>
                            <ListItemText
                              primary={label}
                              secondary={selectedIndex ? null : obj[label][0]}
                            />
                          </ListItem>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {isIntermediaryUser && (
                  <Grid style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <Typography className={styles.text}>Etapa</Typography>
                    <FormControl fullWidth className={styles.select}>
                      <Select
                        onChange={handleSelecStep}
                        MenuProps={menuProps}
                        IconComponent={ExpandMoreIcon}
                        defaultValue={newCommentModal?.comment ? newCommentModal?.comment.step : ''}
                        displayEmpty
                        style={{ height: 65 }}
                      >
                        <MenuItem value="" label="">
                          <ListItem>
                            <ListItemText primary="" secondary="" />
                          </ListItem>
                        </MenuItem>
                        {stepStatusList &&
                          stepStatusList
                            .filter(stepStatus => stepStatus.intermediary === 'intermediary')
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map(filteredStep => (
                              <MenuItem key={filteredStep.id} value={filteredStep.name}>
                                <ListItem>
                                  <ListItemText primary={filteredStep.desc} secondary="" />
                                </ListItem>
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
              <Typography className={styles.text}>
                Mensagem <b className={styles.required}> *</b>
              </Typography>
              <TextField
                className={styles.msgInput}
                variant="outlined"
                multiline
                name="comment_description"
                onChange={handleChangeDescription}
                inputProps={{ maxLength: 1500 }}
                defaultValue={
                  newCommentModal?.comment ? newCommentModal?.comment.message : commentDescription
                }
                required
              />
              <ConfidentialDataWarning style={{ marginTop: 3 }} />
            </Grid>

            <Grid className={styles.column}>
              <Box mt={5} />
              <Box mt={5} />
              <Typography className={styles.text}>
                Tarefa esperada de alguém?<b className={styles.required}> *</b>
              </Typography>
              <FormControl required fullWidth className={styles.select}>
                <Select
                  onChange={handleSelecthasTask}
                  MenuProps={menuProps}
                  defaultValue={handleDefaultTaskValue}
                  displayEmpty
                  disabled={isCsRefera}
                  style={{ height: 50 }}
                >
                  <MenuItem value="no">Não</MenuItem>
                  <MenuItem value="yes_from_my_company">Sim, de minha empresa</MenuItem>
                  <MenuItem value="yes_from_the_other_side">Sim, da outra parte</MenuItem>
                  <MenuItem value="yes_on_both_sides">Sim, de ambas as partes</MenuItem>
                </Select>
              </FormControl>
              <Box mt={5} />
              <DatePicker
                label="Vencimento da Tarefa"
                onChange={handletaskDueChange}
                disabled={hasTask === 'no'}
                value={hasTask === 'no' ? null : taskDue}
                renderInput={inputProps => (
                  <TextField {...inputProps} required={isTaskDueRequired} />
                )}
              />
              <Box mt={5} />
              <FormControlLabel
                control={<Checkbox checked={reminder} onChange={handleReminderChange} />}
                label="Lembrete"
                disabled={hasTask === 'no'}
              />
              <TextField
                label="Dias Úteis"
                type="number"
                onChange={handleWorkingDaysChange}
                disabled={hasTask === 'no'}
                defaultValue={newCommentModal?.comment ? newCommentModal?.comment.workingDays : ''}
                required={isWorkingDaysRequired}
              />
              <DatePicker
                label="Concluída em"
                value={finishIn}
                onChange={handlefinishInChange}
                disabled={hasTask === 'no'}
                renderInput={inputProps => <TextField {...inputProps} />}
              />
              <Box mt={5} />
            </Grid>
          </Grid>

          <Grid>
            <Grid container>
              <Typography variant="h5" component="p" className={styles.text}>
                Anexos
              </Typography>
            </Grid>
            {picturesLoading ? (
              <Grid container justifyContent="center" alignItems="center">
                <CircularProgress size={40} />
              </Grid>
            ) : (
              <>
                <Attachments
                  label="Imagens e vídeos"
                  readOnly={editAttachments}
                  files={imageAttachment || []}
                  accept={{
                    'image/jpeg': ['.jpeg', '.png', '.jpg', '.bmp', '.flv', '.mkv', '.mp4', '.mov'],
                  }}
                  onDrop={files => setCommentAttachment(old => [...old, ...files])}
                  onRemoveItem={index => handleRemoveAttachments(index, false)}
                  onItemClick={index => {
                    window.open(imageAttachment[index]?.file)
                  }}
                />
              </>
            )}
            {PreviewPictureComponent}
            <ConfirmRemoveDialog message={dialogMessage} />
            <ConfirmCancelJustified
              confirmCancelJustified={confirmCancelJustified}
              setConfirmCancelJustified={setConfirmCancelJustified}
            />
          </Grid>
        </DialogContent>
        <DialogActions className={styles.btnWrapper}>
          <Button
            color={BUTTON_COLOR.RED}
            variant="outlined"
            className={styles.button}
            onClick={() => setNewCommentModal({ ...newCommentModal, isOpen: false })}
          >
            Cancelar
          </Button>
          <Button variant="contained" color="primary" className={styles.button} type="submit">
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
